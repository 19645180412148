/* eslint no-unused-vars:0 */
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import {
  Input, Grid, Label, Dropdown, Button, Header, Form, Icon, Modal, Checkbox
} from 'semantic-ui-react'
import { useEffect, useState } from 'react';
import $ from "jquery";

import M5_Border from './images/M5_Edge.png';
import M5_BG from './images/M5_Seamless.png'
import G2_Border from './images/G2_Edge.png';
import G2_BG from './images/G2_Seamless.png'
import G3_Border from './images/G3_Edge.png';
import G3_BG from './images/G3_Seamless.png'
import G4_Border from './images/G4_Edge.png';
import G4_BG from './images/G4_Seamless.png'
import { capture } from './filtershot';

const baseUrl = process.env.REACT_APP_PROD ? 'https://mvhr.shop' : 'http://mvhr-cw.local'
const maxDimension = 1800;
const minDimension = 100;
const preloadImages = [M5_Border, M5_BG, G2_Border, G2_BG, G3_Border, G3_BG, G4_Border, G4_BG];

function App() {
  const [windowWidth, setWindowWidth] = useState($(window).width());
  const [width, setWidth] = useState(150)
  const [height, setHeight] = useState(100)
  const [previewWidth, setPreviewWidth] = useState((width / maxDimension) * 100)
  const [previewHeight, setPreviewHeight] = useState((height / maxDimension) * 100)
  const [tagPosition, setTagPosition] = useState('none')
  const [grade, setGrade] = useState('M5')
  const [valid, setValid] = useState(false)
  const [open, setOpen] = useState(false)
  const [encodedOrder, setEncodedOrder] = useState('')
  const [price, setPrice] = useState(0.00)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [bulkSave, setBulkSave] = useState([])
  const [saveTierIndex, setSaveTierIndex] = useState(-1)
  const [screenshotMode, setScreenshotMode] = useState('')
  const [lockHorizontal, setLockHorizontal] = useState(true)

  useEffect(() => {
    preloadImages.forEach((src) => {
      const img = new Image();
      img.src = src;
      window[src] = img
    });

    const urlParams = new URLSearchParams(window.location.search);
    const model = urlParams.get('model');
    if (model && model !== '') {
      setScreenshotMode(model)
      if (urlParams.get('sku')) {
        const sku = decodeURIComponent(urlParams.get('sku')).split(',')
        setTimeout(() => {
          handleHeight({ target: { value: sku[1] } })
          handleWidth({ target: { value: sku[2] } })
          setPreviewVisible(true)
        }, 800)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleHeight({ target: { value: "265" } })
      handleWidth({ target: { value: "185" } })
      setPreviewVisible(true)
    }, 200)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateDimension = (dimension) => {
    if (!isNaN(dimension)) {
      if (dimension > maxDimension) {
        return maxDimension;
      }
      return dimension;
    }
    return 0
  }

  const handleQuantity = (event) => {
    if (!event.target.value || isNaN(event.target.value) || event.target.value < 1) event.target.value = 1
    if (event.target.value > 200) event.target.value = 200
    const value = parseInt(event.target.value, 10)
    setQuantity(value)
  }

  const handleWidth = (event) => {
    if (!event.target.value) event.target.value = 0
    const value = validateDimension(parseInt(event.target.value, 10))
    setWidth(value)
    const previewWidth = (+value / maxDimension) * 100;
    setPreviewWidth(previewWidth)
  }

  const handleHeight = (event) => {
    if (!event.target.value) event.target.value = 0
    const value = validateDimension(parseInt(event.target.value, 10))
    setHeight(value)
    const previewHeight = (+value / maxDimension) * 100;
    setPreviewHeight(previewHeight)
  }

  const handleTagPosition = (_event, data) => {
    setTagPosition(data.value)
  }

  const getTagClass = () => {
    switch (tagPosition) {
      case 'none':
      default:
        return 'preset-none'
      case 'shortest':
        return lockHorizontal ? 'preset-right' : width > height ? 'preset-right' : 'preset-top'
      case 'longest':
        return lockHorizontal ? 'preset-top' : height > width ? 'preset-right' : 'preset-top'
      case 'longest2':
        return lockHorizontal ? 'preset-top-dual' : height > width ? 'preset-right-dual' : 'preset-top-dual'
    }
  }

  const handleGrade = (_event, data) => {
    setGrade(data.value)
  }

  const handleOrder = () => {
    const order = {}
    order.w = width
    order.h = height
    order.q = quantity
    switch (grade) {
      case 'G2':
      default:
        order.g = 0
        break;
      case 'G3':
        order.g = 1
        break;
      case 'G4':
        order.g = 2
        break;
      case 'M5':
        order.g = 3
        break;
    }
    switch (tagPosition) {
      case 'none':
      default:
        order.t = 0
        break;
      case 'shortest':
        order.t = 1
        break;
      case 'longest':
        order.t = 2
        break;
      case 'longest2':
        order.t = 3
        break;
    }
    const encoded = btoa(JSON.stringify(order))
    setEncodedOrder(encoded)
    setOpen(true)
  }

  const handleOrderConfirmation = () => {
    setOpen(false)
    window.open(`${baseUrl}/cart?customFilterId=${encodeURIComponent(encodedOrder)}`)
  }

  useEffect(() => {
    setValid(false)
    if (width < minDimension) {
      return
    }

    if (width > maxDimension) {
      return
    }

    if (height < minDimension) {
      return
    }

    if (height > maxDimension) {
      return
    }

    let qty = quantity
    if (qty < 1) {
      qty = 1
    }

    let savingTierIndex = -1;

    if (qty >= 2 && qty < 5) {
      savingTierIndex = 0
    } else if (qty >= 5 && qty < 7) {
      savingTierIndex = 1
    } else if (qty >= 7 && qty < 10) {
      savingTierIndex = 2
    } else if (qty >= 10) {
      savingTierIndex = 3
    }

    const quoteForTags = tagPosition !== 'none'

    $.getJSON(`https://middleman.mvhr.shop/quote/wireframe/${width}/${height}/${grade}/?quantity=${qty}&tags=${quoteForTags}&vat=false`)
      .then((response => {
        const bulk = response.bulk
        let total = response.single * qty

        if (savingTierIndex > -1) {
          if (savingTierIndex === 2) {
            total = +response.tenOff * qty
          } else {
            total = (bulk[savingTierIndex].single) * qty
          }
        }

        setSaveTierIndex(savingTierIndex)
        setPrice(parseFloat(total))
        setBulkSave(bulk)
        setValid(true)
      }))
  }, [width, height, tagPosition, grade, quantity])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth($(window).width())
      let filterWrapHeight = 0
      let filterWrapWidth = 0
      if (windowWidth > 767) {
        filterWrapHeight = $('.preview-wrapper').innerHeight()
        filterWrapWidth = $('.preview-wrapper').innerWidth()
        const newWrapSize = Math.min(filterWrapHeight, filterWrapWidth)
        // $('.preview-wrapper').css('height', `${newWrapSize}`)
        $('.preview-wrapper').css('width', `${newWrapSize}`)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth])

  let maximisedPreviewHeight = previewHeight
  let maximisedPreviewWidth = previewWidth
  const incrementPercent = 99
  while (maximisedPreviewHeight < (100 - (maximisedPreviewHeight / incrementPercent)) && maximisedPreviewWidth < (100 - (maximisedPreviewWidth / incrementPercent))) {
    if (maximisedPreviewHeight < 1 || maximisedPreviewWidth < 1) {
      break;
    }
    maximisedPreviewHeight = maximisedPreviewHeight + (maximisedPreviewHeight / incrementPercent)
    maximisedPreviewWidth = maximisedPreviewWidth + (maximisedPreviewWidth / incrementPercent)
  }

  if (lockHorizontal) {
    const heightWidthArr = [maximisedPreviewHeight, maximisedPreviewWidth]
    maximisedPreviewWidth = Math.max(...heightWidthArr)
    maximisedPreviewHeight = Math.min(...heightWidthArr)
  }


  const getPreviewPanel = () => {
    let BG;
    let BORDER;
    switch (grade) {
      case 'G2':
        BG = G2_BG
        BORDER = G2_Border
        break
      case 'G3':
        BG = G3_BG
        BORDER = G3_Border
        break
      case 'G4':
        BG = G4_BG
        BORDER = G4_Border
        break
      default:
      case 'M5':
        BG = M5_BG
        BORDER = M5_Border
        break
    }
    const combinedHeightWidth = [height, width]
    return <div className="preview-panel">
      <div className="preview-wrapper">
        <div className="filter-preview" style={
          {
            paddingLeft: `calc(${maximisedPreviewWidth}% - 40px)`,
            paddingTop: `calc(${maximisedPreviewHeight}% - 40px)`,
            opacity: previewVisible ? 1 : 0,
            borderImage: `url(${BORDER}) ${(windowWidth > 767) ? 55 : 70} round`,
            background: `url(${BG})`
          }}>
          <div className={`filter-tag filter-tag-top ${(windowWidth < 767) && 'mobile'}`} style={{ opacity: `${getTagClass() === `preset-top` ? 1 : 0}` }}></div>
          <div className={`filter-tag filter-tag-right ${(windowWidth < 767) && 'mobile'}`} style={{ opacity: `${getTagClass() === 'preset-right' ? 1 : 0}` }}></div>
          <div className={`filter-tag filter-tag-top-dual ${(windowWidth < 767) && 'mobile'}`} style={{ opacity: `${getTagClass() === 'preset-top-dual' ? 1 : 0}` }}></div>
          <div className={`filter-tag filter-tag-top-dual ${(windowWidth < 767) && 'mobile'}`} style={{ opacity: `${getTagClass() === 'preset-top-dual' ? 1 : 0}`, left: 'calc(10% - 17.5px)' }}></div>
          <div className={`filter-tag filter-tag-right-dual ${(windowWidth < 767) && 'mobile'}`} style={{ opacity: `${getTagClass() === 'preset-right-dual' ? 1 : 0}` }}></div>
          <div className={`filter-tag filter-tag-right-dual ${(windowWidth < 767) && 'mobile'}`} style={{ opacity: `${getTagClass() === 'preset-right-dual' ? 1 : 0}`, top: 'calc(90% - 37.5px)' }}></div>
          <div style={screenshotMode !== '' ? { opacity: 0 } : {}} className="filter-height-dimension">{lockHorizontal ? Math.min(...combinedHeightWidth) : height}mm</div>
          <div style={screenshotMode !== '' ? { opacity: 0 } : {}} className="filter-width-dimension">{lockHorizontal ? Math.max(...combinedHeightWidth) : width}mm</div>
          {screenshotMode !== '' ? <div className="filter-screenshot-model">{screenshotMode.substring(screenshotMode.indexOf(' ') + 1)}</div> : null}
          {screenshotMode !== '' ? <div className="filter-screenshot-disclaimer">Our Filters are not Affiliated with the Original Brand or Manufacturer</div> : null}
          {screenshotMode !== '' ? <div className="filter-screenshot-compatible"><div>Compatible filters for</div>{screenshotMode.split(' ')[0] || null}</div> : null}
        </div>
      </div>
    </div >
  }

  return (
    <div className="MVHR-shop-FD">
      <Grid columns={2} divided stackable style={{ paddingBottom: `${$('.footer').innerHeight()}px` }}>
        <Grid.Row>
          <Grid.Column width={6} style={{ overflowY: 'auto' }} id='MVHR-shop-FD-menu'>
            <div className="filter-inputs">
              <div className="logo">
                <img alt="MVHR.shop logo" src="https://mvhr.shop/wp-content/uploads/2023/11/mvhr.shop_.stacked.small_.png" />
                <Header as='h2'>Custom Filter Designer</Header>
              </div>
              {(windowWidth < 767) && <div>{getPreviewPanel()}</div>}
              <Form>
                {/* <div className="input-wrapper">
                  <Form.Field>
                    <label>Type</label>
                    <Dropdown
                      defaultValue='Wire frame'
                      fluid
                      selection
                      options={[{ key: 0, text: 'Wire frame', value: 'Wire frame' }]}
                    />
                  </Form.Field>
                </div> */}
                <div className="input-wrapper">
                  <Form.Field error={width < 100}>
                    <label>Width</label>
                    <Input fluid type="tel" pattern="[0-9]*" onChange={handleWidth} value={parseInt(width, 10).toString()} placeholder='Width' className="widthInput" labelPosition='right'>
                      <input />
                      <Label basic>mm</Label>
                    </Input>
                  </Form.Field>
                </div>
                <div className="input-wrapper">
                  <Form.Field error={height < 100}>
                    <label>Height</label>
                    <Input fluid type="tel" pattern="[0-9]*" style={{ width: '100%' }} onChange={handleHeight} value={parseInt(height, 10).toString()} placeholder='Height' className="heightInput" labelPosition='right'>
                      <input />
                      <Label basic>mm</Label>
                    </Input>
                  </Form.Field>
                </div>
                <div className="input-wrapper">
                  <Form.Field>
                    <label>Grade</label>
                    <Dropdown
                      onChange={handleGrade}
                      defaultValue='M5'
                      fluid
                      selection
                      options={[{ key: 0, text: 'G2', value: 'G2' }, { key: 1, text: 'G3', value: 'G3' }, { key: 2, text: 'G4', value: 'G4' }, { key: 3, text: 'M5', value: 'M5' }]}
                    />
                  </Form.Field>
                </div>
                <div className="input-wrapper">
                  <Form.Field>
                    <label>Pull Tab Position</label>
                    <Dropdown
                      fluid
                      selection
                      options={[{ key: 0, text: 'None', value: 'none', image: { src: '/tags/none.png' } }, { key: 1, text: 'Tag on shortest side', value: 'shortest', image: { src: '/tags/shortest.png' } }, { key: 2, text: 'Tag on longest side', value: 'longest', image: { src: '/tags/longest.png' } }, { key: 3, text: 'Two tags on longest side', value: 'longest2', image: { src: '/tags/longest2.png' } }]}
                      defaultValue='none'
                      onChange={handleTagPosition}
                    />
                  </Form.Field>
                </div>
              </Form>
              <div className="input-wrapper">
                <div>
                  {screenshotMode !== '' ? <Button onClick={() => capture(screenshotMode)}>Capture</Button> :
                    <Button as='div' labelPosition='right' fluid>
                      <Button labelPosition='left' icon color='yellow' fluid onClick={handleOrder} disabled={!valid}>
                        {windowWidth > 970 || windowWidth < 767 ? <Icon name='shopping basket' /> : null}
                        <div style={{ marginBottom: '0.5em' }}>£{price.toFixed(2)}</div>
                        <span style={{ fontSize: 'smaller' }}>+ VAT</span>
                      </Button>
                      <Label basic pointing='left'>
                        <Input type="number" pattern="[0-9]*" onChange={handleQuantity} value={parseInt(quantity, 10).toString()} fluid placeholder='Quantity' labelPosition='left'>
                          {windowWidth > 970 || windowWidth < 767 ? <Label basic style={{ minWidth: 'unset' }}>Qty</Label> : null}
                          <input style={{ minWidth: '5em' }} />
                        </Input>
                      </Label>
                    </Button>}
                  {/* <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 'smaller' }}>
                    <div className='discount-tiers' style={{ marginBottom: '0.5em' }}>
                      {bulkSave.map((tier, index) => {
                        return <div key={index}><Button fluid active={index === saveTierIndex} onClick={() => setQuantity(tier.quantity)} compact basic size='mini'><Label size='small' circular>{tier.quantity}+</Label><b>£{+tier.single.toFixed(2)}</b>&nbsp;each</Button></div>
                      })}
                    </div>
                    <i className="fitted angle double up icon" style={{ color: '#005056' }}></i><span style={{ margin: '0 0.5em' }}>BUY IN BULK FOR LOWER PRICES</span><i className="fitted angle double up icon" style={{ color: '#005056' }}></i>
                  </div> */}
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={10} style={{ display: (windowWidth > 767) ? 'inline-block' : 'none' }} >
            {!screenshotMode && <div className='filter-preview-controls'><Checkbox onChange={() => setLockHorizontal(!lockHorizontal)} checked={lockHorizontal} label='Lock preview horizontal' /></div>}
            {getPreviewPanel()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="footer">
        <div>
          <b>MVHR.shop © 2023</b>
          <a href="https://mvhr.shop/catalogue" target="_blank" rel="noreferrer">Catalogue</a>
          <a href="https://mvhr.shop/delivery" target="_blank" rel="noreferrer">Delivery</a>
          <a href="https://mvhr.shop/privacy" target="_blank" rel="noreferrer">Privacy</a>
          <a href="https://mvhr.shop/terms" target="_blank" rel="noreferrer">Terms</a>
          <span>Please note that the illustration provided in the filter designer is for preview purposes only, and may not accurately depict scale or dimensions.</span>
        </div>
      </div>
      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size='tiny'
      >
        <Header>
          <img style={{ display: 'block', margin: 'auto' }} src="https://mvhr.shop/wp-content/themes/cw-ecomm-theme/images/mvhr-inverted.png" alt="MVHR.shop logo" />
        </Header>
        <Modal.Content style={{ textAlign: 'center' }}>
          <p>Please be aware that we are unable to offer returns for custom filters, as they are manufactured to your specifications.</p>
          <div className="ui icon black message" style={{ marginTop: '2em' }}>
            <i className="dolly icon"></i>
            <div className="content">
              <p>Estimated lead time for your order is {quantity >= 10 ? <span><b>within 1 working week</b> and will be confirmed after payment</span> : <b>3 - 5 working days</b>}.</p>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: 'center' }}>
          <Button color='red' inverted onClick={() => setOpen(false)}>
            Go back
          </Button>
          <Button color='green' inverted onClick={() => handleOrderConfirmation()}>
            <Icon name='checkmark' /> Continue
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default App;
