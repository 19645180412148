export const capture = async (model) => {
    const mainContentArea = document.querySelector(".preview-panel");
    const cropTarget = await window.CropTarget.fromElement(mainContentArea);

    const stream = await navigator.mediaDevices.getDisplayMedia({ preferCurrentTab: true });
    const [track] = stream.getVideoTracks();

    await track.cropTo(cropTarget);

    setTimeout(async () => {
        const canvas = await drawToCanvas(stream);
        stream.getTracks()
            .forEach(track => track.stop())

        let downloadLink = document.createElement('a');
        const fileName = model.replace(/[^A-Z0-9]/ig, "_");
        downloadLink.setAttribute('download', `${fileName}.png`);
        canvas.toBlob(blob => {
            let url = URL.createObjectURL(blob);
            downloadLink.setAttribute('href', url);
            downloadLink.click();
        });
    }, 1000)

    async function drawToCanvas(stream) {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");
        video.srcObject = stream;
        await video.play();
        const combinedHeightWidth = [video.videoHeight, video.videoWidth]
        const croppedDimension = (Math.min(...combinedHeightWidth) - 50)
        canvas.width = croppedDimension;
        canvas.height = croppedDimension;
        const leftOffset = ((Math.max(...combinedHeightWidth) - Math.min(...combinedHeightWidth) + 50) / 2)
        canvas.getContext("2d").drawImage(video, leftOffset, 0, croppedDimension, croppedDimension,
            0, 0, croppedDimension, croppedDimension,);
        return canvas;
    }
};